import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return  <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="How To Stock A Pantry For A Year"
            description="Stocking a pantry for a year is a significant undertaking. But once you break it down into actionable steps, it isn't that difficult to pull off."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                How To Stock A Pantry For A Year
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/how-to-stock-a-pantry-for-a-year.jpg" alt="How To Stock A Pantry For A Year"/>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Reasons why you might want to stock a pantry for an entire year:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Possibility of a food shortage</UnorderedListItem>
            <UnorderedListItem>Possibility of war</UnorderedListItem>
            <UnorderedListItem>Prices rising so high who knows how much food is going to cost in the next few months</UnorderedListItem>
            <UnorderedListItem>You're ready to drastically reduce the number of times you go grocery shopping, either because of unfavorable seasons (as in, winter is coming) or as a personal challenge</UnorderedListItem>
            <UnorderedListItem>Possibility of zombie apocalypse</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Whatever the case may be, stocking a pantry for twelve months is a significant undertaking. However, once you break it down into actionable steps, it may not be that difficult to pull off.
        </Paragraph>
        <Paragraph>
            Here is everything you need to do to <InternalLink to="/pantry-stock-list/">stock a pantry</InternalLink> for a year.
        </Paragraph>
        <BlogHeading2>
            Step 1: Figure out how much food that is exactly
        </BlogHeading2>
        <Paragraph>
            You cannot just go in blindly and get as many basic pantry staples as your car can accommodate. With a big project such as this, planning and organizing is half the battle.
        </Paragraph>
        <Paragraph>
            First, you need to know how much of each pantry item your household goes through every month. If you don't know this already, it would be best to take one or maybe even two months to keep track. How many cans of tuna do you use up? How much flour? What about jars of jam? Or chocolate chips or canned tomatoes? How many bottles of soy sauce goes into a month's worth of cooking for the whole household?
        </Paragraph>
        <Paragraph>
            Tracking is tedious work, but without this crucial piece of information - how much of which pantry staple you use up in a month - you cannot hope to stock your pantry for a long period of time and be successful at it.
        </Paragraph>
        <Paragraph>
            When you have the monthly numbers in front of you, you can multiply them by 12 or however many months you want to stock up for. If you're the type of person who wants to be absolutely sure you will have more than enough, maybe multiply by one month more than what you're planning for. Just in case.
        </Paragraph>
        <BlogHeading2>
            Step 2: Learn which foods can last for a year
        </BlogHeading2>
        <Paragraph>
            Once you have a comprehensive list of what you regularly use and in what quantities, it's time to analyze that list.
        </Paragraph>
        <Paragraph>
            The question we want to answer here is: what foods have a year-long shelf life? Because some won't last for a year even if you buy enough of them (such as onions and potatoes, which you likely keep in your pantry). 
        </Paragraph>
        <Paragraph>
            Here is a breakdown of the basic pantry items that can last for at least a year (if you store them properly).
        </Paragraph>
        <BlogHeading3>
            Foods that last indefinitely
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Dried beans (though they do become harder with age, so cooking time has to increase)</UnorderedListItem>
            <UnorderedListItem>Sugar (any kind, brown sugar, granulated sugar, or powdered sugar)</UnorderedListItem>
            <UnorderedListItem>Syrup (such as maple syrup or corn syrup)</UnorderedListItem>
            <UnorderedListItem>Honey</UnorderedListItem>
            <UnorderedListItem>Salt</UnorderedListItem>
            <UnorderedListItem>Pure vanilla extract</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Hard liquor (not wine or beer, but rather distilled spirits)</UnorderedListItem>
            <UnorderedListItem>Uncooked rice (any kind - white rice, brown rice, etc.)</UnorderedListItem>
            <UnorderedListItem>Vinegars (white distilled vinegar, apple cider vinegar, balsamic vinegar, etc.)</UnorderedListItem>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Energy bars</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Foods that last for decades
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Soy sauce</UnorderedListItem>
            <UnorderedListItem>Sealed instant coffee</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
            <UnorderedListItem>Dried pasta</UnorderedListItem>
            <UnorderedListItem>Frozen foods (as long as they are kept frozen)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Foods that last for years
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Unpopped corn kernels (popcorn)</UnorderedListItem>
            <UnorderedListItem>Dark chocolate</UnorderedListItem>
            <UnorderedListItem>Powdered milk</UnorderedListItem>
            <UnorderedListItem>Certain hard cheeses, such as Parmesan or aged cheddar</UnorderedListItem>
            <UnorderedListItem>Peanut butter (the kind that doesn't have to be refrigerated)</UnorderedListItem>
            <UnorderedListItem>Low-acid canned goods (canned meat, canned tuna or other types of canned fish, canned corn, canned beans, etc.)</UnorderedListItem>
            <UnorderedListItem>Uncooked quinoa</UnorderedListItem>
            <UnorderedListItem>Spam (unopened)</UnorderedListItem>
            <UnorderedListItem>Hardtack</UnorderedListItem>
            <UnorderedListItem>Ramen noodles</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
            <StaticImage src = "../assets/images/blogs/post-2022-9-stock-a-pantry-for-a-year-a-pile-of-glass-jars-filled-with-pantry-food.png" alt = "Stacked glass jars filled with dehydrated meat and various nuts and seeds."/>
        </ImageWithCaption>
        <BlogHeading3>
            Foods that last up to a year
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Hard candies, jellied candies, caramel</UnorderedListItem>
            <UnorderedListItem>Dehydrated meat</UnorderedListItem>
            <UnorderedListItem>Bouillon cubes</UnorderedListItem>
            <UnorderedListItem>Cake mix</UnorderedListItem>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
            <UnorderedListItem>Dark, unsweetened cocoa</UnorderedListItem>
            <UnorderedListItem>Flour</UnorderedListItem>
            <UnorderedListItem>Dried fruit</UnorderedListItem>
            <UnorderedListItem>Unopened spice containers</UnorderedListItem>
            <UnorderedListItem>Unopened olive oil</UnorderedListItem>
            <UnorderedListItem>Jarred pasta sauce</UnorderedListItem>
            <UnorderedListItem>High-acid canned goods (canned fruit, pickles, sauerkraut, canned tomato soup, etc.)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
             Step 3: Prepare pantry space
        </BlogHeading2>
        <Paragraph>
            As you can see, there are plenty of kitchen pantry items you can stockpile for an entire year. Once you decide on what you need and how much of it, there's only one thing to do before you start shopping - prepare the food storage space.
        </Paragraph>
        <Paragraph>
            A big stipulation here is that the above-listed items will last that long only if they are stored properly. Usually this means that they need to be kept in a dry, cold place where they aren't directly exposed to moisture or sunlight. Frozen foods need to be kept in the freezer, of course.
        </Paragraph>
        <Paragraph>
            Some items can and should be kept in their original packaging (such as sealed and vacuum-packed things), while you may want to transfer others into airtight containers. For example, sugar, flour, powdered milk, dry corn, and similar should be kept in an airtight container. 
        </Paragraph>
        <Paragraph>
            Make sure you have enough space for what you're planning to keep. That means plenty of empty cupboards, shelves, and maybe even one entire small room converted into a homestead pantry.
        </Paragraph>
        <Paragraph>
            If you live in a region that gets extremely hot in the summer (or any other time of the year), think how you will keep this space cool so that your carefully collected pantry essentials don't spoil.
        </Paragraph>
        <BlogHeading2>
            Step 4: Shopping time!
        </BlogHeading2>
        <Paragraph>
            With a neat shopping list and plenty of storage space, it's time to visit the grocery store. Here are some tips to make this process easier:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Don't go alone</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            If you have small children, it's best to leave them at home with someone to look after them while you shop. If you have teenagers or older kids, they can come help. You may want to ask an adult or two to come with you as well. Fact is, you will have a lot of stuff to carry (and we mean a lot), so it wouldn't be the best idea to do it all by yourself.
        </Paragraph>
        <UnorderedList>
        <UnorderedListItem>Split it up into several shopping trips</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            It would be asking for too much to buy the entirety of your yearly pantry supplies in one go. Both your budget and your physical capability (even with helpers) would have a thing or two to say about that.
        </Paragraph>
        <Paragraph>
            So don't be afraid to split this ambitious undertaking into several shopping trips. Divide your list into manageable sections and get everything in chunks. Even if it takes a month or so to bring it all into your home, it'd be worth it - you won't have to worry about stocking your pantry for a long time to come.
        </Paragraph>
        <BlogHeading2>
            Step 5: Don't forget to rotate
        </BlogHeading2>
        <Paragraph>
            With your year's worth of pantry items set up and neatly arranged in the designated space, you can breathe a sigh of relief. At least temporarily.
        </Paragraph>
        <Paragraph>
            The final step would be to keep track of those items and not lose sight of what might be expiring soon. If you have any cans or jars leftover from the previous year, make sure those are at the front of the stack. Rotate your items so that the oldest are easy to reach first and the newly bought items are in the back. That way you won't accidentally use up something that would have lasted for a while longer, while a different item with a shorter shelf life remains untouched.
        </Paragraph>
        <BlogHeading2>
            Conclusion: Practice makes perfect
        </BlogHeading2>
        <Paragraph>
            It might take some trial and error until you get the whole 'stockpiling for a year' process down pat. But if you keep at it, year after year, you will get better and better at calculating just the right amounts of pantry supplies and storing them and organizing them so that you don't have to worry about anything pantry-related until next year.
        </Paragraph>
        <Paragraph>
            We hope that our five steps helped you get a clearer picture of what you need to do and where you should start.
        </Paragraph>
        <Paragraph>
            Remember that an efficiently organized shopping list is one of the cornerstones of this task. Having a place where you can write all of the items down and sort them according to your shopping plans will make building a well stocked pantry so much easier.
        </Paragraph>
        <Paragraph>
            Going Shopping, our <InternalLink to="/#download-section">free grocery shopping list app</InternalLink>, has all the features you need to turn this into a reality.
        </Paragraph>
    </StyledMainContent>
        </BlogPostWrapper>
}



export default BlogPost;